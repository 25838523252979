<template>
    <ODataLookup :data-object="dsScope_ProcessTreLkp" :whereClause="computedWhereClause" ref="lookupRef">
        <OColumn field="Name" width="400" />
        <OColumn field="AppName" width="300"></OColumn>
        <template #target="scope">
            <slot name="target" :target="scope.target" :open="scope.open"></slot>
        </template>
        <template #toolbarActions>
            <OToolbarAction v-model="showAll" :text="$t('Include All Processes')" />
        </template>
    </ODataLookup>
</template> 

<script setup>
import { ref, defineProps, computed, watch } from "vue";
import { getDataObjectById } from 'o365-dataobject';

const lookupRef = ref();
const showAll = ref(false);

const props = defineProps({
    detailAppId: {
        type: String,
        default: null
    },
    whereClause: {
        type: String,
        default: ''
    },
    dataObject: {
        type: Object,
        default: null
    }
});

const whereClause = props.whereClause;

const computedWhereClause = computed(() => {
    var clauses = []; 
    if(whereClause.length > 0){
        clauses.push(whereClause);
    }
    if(props.detailAppId != null){
        clauses.push(`DetailApp_ID = '${props.detailAppId}'`)
    }

    return clauses.join(" AND ");
})

const dsScope_ProcessTreLkp = props.dataObject ?? getDataObjectById("dsScope_ProcessTreLkp");

function updateProcesses(){
    if(showAll.value){
        dsScope_ProcessTreLkp.recordSource.whereClause = whereClause;
        dsScope_ProcessTreLkp.load();
    } else {
        dsScope_ProcessTreLkp.recordSource.whereClause = computedWhereClause.value;
        dsScope_ProcessTreLkp.load();
    }
}

watch(showAll, () => updateProcesses());

</script>